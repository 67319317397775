
import Header from '../header/header';
import './homeStyles.css';
import Lottie from "lottie-react";
import brainAnimi from '../animations/Brain.json'
import particleTop from '../assets/particle-top.svg'
import particleBottom from '../assets/particle-bottom.svg'
import Footer from '../footer/footer';
function Home() {
  return (
    <div className="home">
    <div className='particle-top'>
        <img src={particleTop}></img>
    </div>
    <Header/>
    <main className='main'>
    <section className='left-section'>
    <Lottie animationData={brainAnimi} loop={false} />
    </section>
    <section className='right-section'>
     <div className='top-lines'>
     <span className='line1'></span>
     <span className='line2'></span>
     </div>
     <div className='content'>
      <h1>let AI work</h1>
      <span>whiteink.ai simplifies decision making and transforms intra-company communication.</span>
     </div>
     <div className='bottom-lines'>
     <span className='line3'></span>
     <span className='line4'></span>
     <span className='line5'></span>
     </div>

    </section>

    </main>
    <div className='particle-bottom'>
    <img src={particleBottom}></img>
    </div>
    <Footer/>

    </div>
  );
}

export default Home;
