
import './headerStyle.css';

function Header() {
  return (
    <div className="header">
    <div className='logo'>
        <a href='/'>whiteink.ai</a>
    </div>

    </div>
  );
}

export default Header;